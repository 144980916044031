import React, { createContext, useState } from "react";

export const CashContext = createContext();

export const CashProvider = ({ children }) => {
  const [selectedLevel, setSelectedLevel] = useState("All");

  //sidebar amburer toggle
  const [toggleSidebar, setToggleSidebar] = useState(false);

  // Set the default to date as today
  const today = new Date();

  // Set the default 'from' date to be 30 days prior to today
  const defaultFromDate = new Date();
  defaultFromDate.setDate(today.getDate() - 30);

  // Set the default 'to' date to be 30 days after today
  const defaultToDate = new Date();
  defaultToDate.setDate(today.getDate() + 30);

  const [dataIndex, setDataIndex] = useState(1);
  const [fromValue, setFromValue] = useState(defaultFromDate);
  const [toValue, setToValue] = useState(defaultToDate)

  const defaultValue = {
    selectedLevel,
    setSelectedLevel,
    dataIndex, setDataIndex,
    fromValue, setFromValue,
    toValue, setToValue,
    toggleSidebar,
    setToggleSidebar
  };
  return (
    <CashContext.Provider value={{ ...defaultValue }}>
      {children}
    </CashContext.Provider>
  );
};
