import React, { useState, useEffect } from "react";
import {
  Container,
  Col,
  Grid,
  Text,
  Button,
  Paper,
  ThemeIcon,
  Image,
  Modal,
  TextInput,
} from "@mantine/core";
import { IconGauge, IconSelector } from "@tabler/icons-react";
import bg from "../assets/landing/bg.png";
import FeaturesBg from "../assets/landing/features_bg.png";
import Contact_bg from "../assets/landing/contact_bg.png";
import Logo_white from "../assets/logo_white.png";
import icon from "../assets/icon.png";
import combine from "../assets/combine.jpeg";
import "../components/Landing/LandingPage.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/logo_white.png";
 
const LandingPage = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const navigate = useNavigate(); // Add this line
  const [isFormSubmitted, setFormSubmitted] = useState(false);
 
  const toggleMobileMenu = () => {
    setIsMobileMenuVisible(!isMobileMenuVisible);
  };
 
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
  };
  // const handleLoginClick = () => {
  //   // Add the login path URL here
  //   const loginPath = "/dashboard/login";
 
  //   // Navigate to the login page
  //   navigate(loginPath); // Change this line
  // };
  const handleLoginClick = () => {
    // Add the login path URL here
    const loginPath = "/home";
   
    // Redirect to the login page
    window.location.href = loginPath;
  };
 
  useEffect(() => {
    handleResize(); // Initial check on component mount
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
 
  const backgroundStyles = {
    backgroundImage: `url(${bg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
 
  const [isModalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email:"",
    organization: "",
    message: "",
  });
  const handleOpenModal = () => {
    setModalOpen(true);
  };
 
  const handleCloseModal = () => {
    setModalOpen(false);
  };
 
  const handleSubmit = async (event: any) => {
    event.preventDefault(); // Prevent the default form submission behavior
 
    const mappedFormData = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      organization: formData.organization,
      how_heard_about_us: formData.message,
    };
 
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_MVUE_API_BASEURL}/submit-form`,
        mappedFormData
      );
      console.log("Form submitted successfully", response);
 
      setFormData({
        firstName: "",
        lastName: "",
        email:"",
        organization: "",
        message: "",
      });
      setFormSubmitted(true);

    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };
 
  return (
    <div>
      <Container size="xl">
        <header
          style={{
            height: "10vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 20px",
          }}
        >
          <div className="logo1" style={{ flex: 1, textAlign: "left" }}>
            <img
              src={icon}
              alt="Your Logo"
              style={{ maxWidth: "1.5rem", height: "auto", marginLeft: "6vw" }}
            />
            <img src={Logo_white} alt="Your Logo" className="logo" />
          </div>
          {/* Conditional rendering of the normal buttons based on screen size */}
          {isMobile ? (
            <div className="mobile-menu">
              <IconSelector
                size="8vw"
                color="#000"
                style={{
                  cursor: "pointer",
                  marginRight: "20px",
                }}
                onClick={toggleMobileMenu}
              />
              {isMobileMenuVisible && (
                <div className="mobile-buttons">
                  <Button
                    style={{
                      borderRadius: "1vw",
                      width: "85px",
                      height: "20px",
                      marginBottom: "2vw",
                      fontSize: "10px",
                    }}
                    onClick={handleLoginClick}
                  >
                    Log in
                  </Button>
                  <Button
                    onClick={handleOpenModal}
                    style={{
                      borderRadius: "1vw",
                      width: "120px",
                      height: "20px",
                      fontSize: "10px",
                    }}
                  >
                    Schedule a Demo
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <div
              className="header-buttons"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Button
                style={{ borderRadius: "1vw" }}
                onClick={handleLoginClick}
              >
                Log in
              </Button>
              <Button
                onClick={handleOpenModal}
                style={{
                  borderRadius: "1vw",
                  marginLeft: "2vw",
                  marginRight: "6vw",
                }}
              >
                Schedule a Demo
              </Button>
            </div>
          )}
        </header>
 
        <div style={backgroundStyles}>
          <div style={{ width: "60%" }}>
            <Text
              className="star"
              style={{
                color: "#1b7b64",
                fontWeight: "bold",
                paddingRight: "1vw",
                paddingTop: "10vw",
                paddingLeft: "9vw",
              }}
            >
              
CFOs and Treasury Professionals
            </Text>
 
            <Text
              className="hero1"
              style={{ paddingLeft: "9vw", color: "gray" }}
            >
              Take bold decisions with our
            </Text>
 
            <Text
              className="hero"
              style={{
                fontWeight: "bold",
                paddingRight: "1vw",
                paddingTop: "0.5vw",
                paddingLeft: "9vw",
                lineHeight: "1.2",
              }}
            >
              AI-Driven Predictive Cash Flow Solution for Modern Enterprises
            </Text>
            <Text
              className="hero1"
              style={{ paddingTop: "1vw", paddingLeft: "9vw", color: "gray" }}
            >
              Real time Cash Visibilty, Predictive Cashflow Trends Simulate
              Scenarios, Visualize the Future.
            </Text>
    
            <Button
              className="demo-bt"
              onClick={handleOpenModal}
              style={{
                marginTop: "2vw",
                borderRadius: isMobile ? "6vw" : "1vw",
                marginRight: "6vw",
                width: isMobile ? "200px" : " ", // Set the width conditionally
                height: isMobile ? "30px" : " ", // Set the height conditionally
                marginLeft: isMobile ? "9vw" : "9vw",
                marginBottom: isMobile ? "3vw" : "3vw",
              }}
            >
              Schedule a Demo
            </Button>
          </div>
        </div>
 
        <div
          style={{
            backgroundImage: `url(${FeaturesBg})`,
            backgroundSize: "cover",
          }}
        >
          <Grid gutter="md" style={{ marginTop: "3vw" }}>
            <Col
              span={12}
              sm={6}
              style={{
                display: "flex",
                flexDirection: "column", // Set to column
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                className="Leverage"
                style={{ paddingLeft: "10vw", fontWeight: "bold" }}
              >
                Leverage AI for your financial operations
              </Text>
              <Button
                onClick={handleOpenModal}
                style={{
                  borderRadius: "1rem",
                  marginTop: "1vw", // Adjusted margin to create space below Text
                  alignSelf: "flex-start", // Align button to the left
                  marginLeft: "10vw",
                }}
              >
                Schedule a Demo
              </Button>
            </Col>
 
            <Col span={12} sm={6}>
              <Grid gutter="md" style={{ padding: "3vw", display: 'flex', flexWrap: 'wrap' }}>
                <Col span={12} sm={6} style={{ display: 'flex' }}>
                  <Paper style={{ padding: "xl", backgroundColor: "#f8f8f8" }}>
                    <ThemeIcon
                      color="gray"
                      variant="light"
                      size={40}
                      radius={40}
                      mb={8}
                    >
                      <IconGauge size="2vw" color="#2E8C7D" stroke={1.5} />
                    </ThemeIcon>
                    <Text
                      size="md"
                      style={{ paddingLeft: "1vw", fontWeight: "500" }}
                    >
                      Realtime Cash Visibility
                    </Text>
                    <Text
                      size="sm"
                      style={{
                        paddingLeft: "1vw",
                        paddingRight: "3vw",
                        paddingTop: "1vw",
                        paddingBottom: "2vw",
                        color: "grey",
                      }}
                    >
                      With pre-built integrations with multiple banks & ERP
                      systems, MVue enables organizations to get real-time
                      visibility into their cash positions. This helps CFO’s to
                      proactively manage their incremental cash flows and
                      optimize liquidity.
                    </Text>
                  </Paper>
                </Col>
                <Col span={12} sm={6} style={{ display: 'flex' }}>
                  <Paper style={{ padding: "xl", backgroundColor: "#f8f8f8" }}>
                    <ThemeIcon
                      color="gray"
                      variant="light"
                      size={40}
                      radius={40}
                      mb={8}
                    >
                      <IconGauge size="2vw" color="#2E8C7D" stroke={1.5} />
                    </ThemeIcon>
                    <Text
                      size="md"
                      style={{ paddingLeft: "1vw", fontWeight: "500" }}
                    >
                      Forecasting Accuracy
                    </Text>
                    <Text
                      size="sm"
                      style={{
                        paddingLeft: "1vw",
                        paddingRight: "3vw",
                        paddingTop: "1vw",
                        paddingBottom: "2vw",
                        color: "grey",
                      }}
                    >
                      By leveraging AI and predictive insights, MVue
                      significantly enhances the accuracy of cash flow
                      forecasting, empowering CFOs to make informed financial
                      decisions. The platform mitigates human biases and
                      leverages machine learning techniques to ensure greater
                      precision in forecasting.
                    </Text>
                  </Paper>
                </Col>
                <Col span={12} sm={6}>
                  <Paper style={{ padding: "xl", backgroundColor: "#f8f8f8" }}>
                    <ThemeIcon
                      color="gray"
                      variant="light"
                      size={40}
                      radius={40}
                      mb={8}
                    >
                      <IconGauge size="2vw" color="#2E8C7D" stroke={1.5} />
                    </ThemeIcon>
                    <Text
                      size="md"
                      style={{ paddingLeft: "1vw", fontWeight: "500" }}
                    >
                      Automation and Efficiency.
                    </Text>
                    <Text
                      size="sm"
                      style={{
                        paddingLeft: "1vw",
                        paddingRight: "3vw",
                        paddingTop: "1vw",
                        paddingBottom: "2vw",
                        color: "grey",
                      }}
                    >
                      MVue automates the cash flow forecasting process, reducing
                      the time required to produce accurate forecasts. By
                      streamlining and automating manual tasks, finance teams
                      can focus on value-added activities and financial
                      analysis, resulting in improved overall efficiency.
                    </Text>
                  </Paper>
                </Col>
                <Col span={12} sm={6}>
                  <Paper style={{ padding: "xl", backgroundColor: "#f8f8f8" }}>
                    <ThemeIcon
                      color="gray"
                      variant="light"
                      size={40}
                      radius={40}
                      mb={8}
                    >
                      <IconGauge size="2vw" color="#2E8C7D" stroke={1.5} />
                    </ThemeIcon>
                    <Text
                      size="md"
                      style={{ paddingLeft: "1vw", fontWeight: "500" }}
                    >
                      Data Visualization and Analytics
                    </Text>
                    <Text
                      size="sm"
                      style={{
                        paddingLeft: "1vw",
                        paddingRight: "3vw",
                        paddingTop: "1vw",
                        paddingBottom: "2vw",
                        color: "grey",
                      }}
                    >
                      Mvue delivers significantly advances Data Visualization
                      capabilities to analyze, break down and segment cash flows
                      in multiple customizable dimensions to enable a
                      comprehensive understanding of cash flows for detailed
                      in-sights into cash flow patterns for optimized decision
                      making.
                    </Text>
                  </Paper>
                </Col>
              </Grid>
            </Col>
          </Grid>
        </div>
 
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
            backgroundColor: "#187b67",
          }}
        >
          <Grid gutter="md">
            <Col span={12} sm={5} style={{ padding: "10vw" }}>
              <Text
                className="hero1"
                color="#7df1dc"
                style={{ fontWeight: "600" }}
              >
                CFOs & CAOs
              </Text>
              <Text color="#ffffffd4" className="description">
                With scenario analysis and real-time AI enabled predictive
                insights into cash flow performance CFOs and CAOs can simulate
                the financial outcome of various business scenarios, free up
                Incremental cash flows and deploy strategies for liquidity
                optimization.
              </Text>
              <Text
                className="hero1"
                color="#7df1dc"
                style={{ fontWeight: "600", marginTop: "2vw" }}
              >
                Treasury & Finance Leaders
              </Text>
              <Text color="#ffffffd4" className="description">
                Mvue reduces time to create Cash Forecasts dramatically by
                consolidating cash inflows and outflows data across multiple
                systems into a centralized data platform. It enables teams to
                make critical decisions faster for cash allocations, pooling and
                currency hedging.
              </Text>
            </Col>
            <Col
              span={12}
              sm={5}
              style={{
                marginLeft: "10vw",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Text color="white" className="unlock">
              Unlock Your Business Potential
              </Text>
              <Text
                size="2.5vw"
                color="white"
                className="one"
                style={{ marginTop: "1vw", fontWeight: "650" }}
              >
                One easy-to-use platform to empower your financial decisions
              </Text>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  width: "100%",
                  paddingTop: "1vw",
                }}
              >
                <Button
                  onClick={handleOpenModal}
                  style={{
                    borderRadius: "1rem",
                    marginTop: "1rem",
                    marginRight: "6vw",
                  }}
                >
                  Schedule a Demo
                </Button>
              </div>
            </Col>
          </Grid>
        </div>
 
        <div
          style={{
            backgroundImage: `url(${Contact_bg})`,
            marginTop: "1vw",
            marginLeft: "8vw",
            marginRight: "8vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start", 
            height: "35vh",
            borderRadius: "1vw",
            padding: "3vw",
            backgroundSize: "cover",
          }}
        >
          <Text
            className="demo"
            color="white"
            fw={600}
            style={{ width: "50%" }}
          >
            Get in touch now!
          </Text>
          <Text
            color="white"
            className="description"
            style={{ width: "43%", paddingTop: "1vw" }}
          >
            Our team can help answer questions about your account, privacy and
            security, or product advice. Please contact us at <a href="#" className="description" style={{ color: "white" }}>mvue@calfus.com.</a>
          </Text>
          <Button
            onClick={handleOpenModal}
            style={{
              width: "10.2rem",
              height: "2.2rem",
              borderRadius: "1rem",
              marginTop: "1.2rem",
              fontSize: "0.9rem",
            }}
          >
            Schedule a Demo
          </Button>
        </div>
 
        <footer>
          <Grid gutter="md" mt={"2vw"} style={{ display: "flex" }}>
            <Col span={6}>
              <div>
                <img
                  src={combine}
                  alt="Your Logo"
                  style={{
                    maxWidth: "15rem",
                    height: "2rem",
                    paddingLeft: "6vw",
                  }}
                />
              </div>
            </Col>
            <Col span={6}>
              <div
                className="description"
                style={{
                  textAlign: "left",
                  marginLeft: "18vw",
                  color: "gray",
                  marginBottom: "2vw",
                }}
              >
               <a href="https://www.calfus.com/" target="_blank" style={{cursor: "pointer",  color: "gray",}} >About Us</a>

                {/* <Text>Features</Text>
                <Text>Pricing</Text>
                <Text>Support</Text>
                <Text>Forum</Text> */}
              </div>
            </Col>
          </Grid>
 
          <div
            className="footer"
            style={{ textAlign: "center", color: "grey", marginBottom: "1vw" }}
          >
            © 2024 Calfus Inc. All rights reserved.
          </div>
        </footer>
      </Container>
      <Modal opened={isModalOpen} onClose={handleCloseModal} radius={30}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image
            src={Logo}
            style={{
              padding: "1vw",
              width: "13vw",
              height: "rem(50)",
            }}
          />
        </div>
 
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            p={"1vw"}
            fw={"600"}
            style={{ fontSize: "1.2vw", marginBottom: "1.5vw" }}
          >
            Request a Demo
          </Text>
        </div>
 
        {isFormSubmitted ? (
          <div>
            <Text p={"1vw"} fw={"600"} style={{ fontSize: "1.2vw", marginBottom: "1.5vw",  textAlign: "center", color:"#1b7b64"}}>
              Form submitted successfully!
            </Text>
            {/* You can add additional information or actions here */}
          </div>
        ) : (
        <form onSubmit={handleSubmit}>
          <TextInput
            placeholder="First Name"
            required
            value={formData.firstName}
            onChange={(event) =>
              setFormData({ ...formData, firstName: event.target.value })
            }
            className="custom-placeholder"
          />
 
          <TextInput
            placeholder="Last Name"
            required
            value={formData.lastName}
            style={{ marginTop: "3vh" }}
            onChange={(event) =>
              setFormData({ ...formData, lastName: event.target.value })
            }
            className="custom-placeholder"
          />
 
          <TextInput
            placeholder="Email"
            required
            value={formData.email}
            style={{ marginTop: "3vh" }}
            onChange={(event) =>
              setFormData({ ...formData, email: event.target.value })
            }
            className="custom-placeholder"
          />
 
          <TextInput
            placeholder="Organization"
            required
            value={formData.organization}
            style={{ marginTop: "3vh" }}
            onChange={(event) =>
              setFormData({ ...formData, organization: event.target.value })
            }
            className="custom-placeholder"
          />
 
          <TextInput
            placeholder="How did you hear about us?"
            required
            value={formData.message}
            style={{ marginTop: "3vh" }}
            onChange={(event) =>
              setFormData({ ...formData, message: event.target.value })
            }
            className="custom-placeholder"
          />
 
          <Button
            type="submit"
            style={{ marginTop: "3vh", width:"100%" }}
          >
            Submit
          </Button>
          <Text ta="center" mt="xl" size={"sm"}>
            Have trouble logging in? Please contact{" "}
          </Text>
          <Text ta="center" size={"sm"}>
            <a
              href="mailto:mvue@calfus.com"
              style={{ fontWeight: "700", color: "#1b7b64" }}
            >
              mvue@calfus.com
            </a>
          </Text>
        </form>
        )}
      </Modal>
    </div>
  );
};
 
export default LandingPage;
