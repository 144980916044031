import React, { useState } from "react";
import {
  MantineProvider,
  ColorSchemeProvider,
  ColorScheme,
} from "@mantine/core";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


import { useNavigate } from "react-router-dom";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { CashProvider } from "./context";
import { LoginCallback } from "@okta/okta-react";

import LandingPage from "./pages/LandingPage";
interface AuthGuardProps {
  children: React.ReactNode;
}
// const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
//   const navigate = useNavigate();
//   // Check if the user is authenticated
//   const userDetailsString = localStorage.getItem("userDetails");
//   const userDetails = userDetailsString ? JSON.parse(userDetailsString) : null;
//   const isAuthenticated = !!userDetails;
//   if (!isAuthenticated) {
//     // Redirect to the login page if not authenticated
//     // navigate("/demo");
//     setTimeout(() => {
//       navigate("/");
//     }, 50);
//     return null;
//   }
//   return <>{children}</>;
// };
export default function MainApp() {
  const [colorScheme, setColorScheme] = useState<ColorScheme>("dark");
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "light" ? "light" : "dark"));
  const ssoEnabled = process.env.REACT_APP_SSO_ENABLED;
  console.log("ssoEnabled: " + ssoEnabled);
  // const oktaAuth = new OktaAuth({
  //   clientId: "0oaarmekwvj1mnUId5d7",
  //   issuer: "https://dev-54253549.okta.com/oauth2/default",
  //   redirectUri: "http://localhost:3000/login/callback",
  //   logoutUrl: "http://localhost:3000/logout",
  // });
  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        withCSSVariables
        withGlobalStyles
        withNormalizeCSS
        theme={{
          colors: {
            calfus: [
              "#D1F0EB",
              "#A5E2D8",
              "#7FD5C7",
              "#5DCAB9",
              "#40C1AC",
              "#36A593",
              "#2E8C7D",
              "#27776A",
              "#21655A",
              "#1C564D",
            ],
            background: ["#e4ebf1"],
            light_gray: ["#fcfcfc"],
            landing: ["#E8F1ED", "#187A66"],
            landing_stats: ["#04454E"],
          },
          primaryColor: "calfus",
          defaultGradient: { from: "cyan", to: "yellow" },
          colorScheme: "light",
        }}
      >
        <CashProvider>
          {ssoEnabled === "1" ? (
            <div>
              <Routes>
                {/* <Route path="/login" element={<LoginCallback />} /> */}
                <Route path="/" element={<LandingPage />} />
                {/* <Route
                  path="/"
                  element={
                    <OktaAuthGuard>
                    </OktaAuthGuard>
                  }
                >
                </Route> */}
              </Routes>
            </div>
          ) : (
            <div>
              <Routes>
                {/* <Route path="/login" element={<LoginPage />} /> */}
                <Route path="/" element={<LandingPage />} />
                {/* <Route
                  path="/*"
                  element={
                    <AuthGuard>
                    </AuthGuard>
                  }
                >
                </Route> */}
              </Routes>
            </div>
          )}
        </CashProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}
